import React from 'react';
import { Controller } from 'react-hook-form';
import { cn } from 'src/lib/utils';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { Textarea } from 'src/components/ui/textarea';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/ui/select';
import LocationFields from './LocationFields';

const FormSection = ({ title, children }) => (
  <div className="mt-3 relative">
    <div className="absolute -top-3 left-4 px-2 bg-background">
      <h4 className="font-bold pageTitle">{title}</h4>
    </div>
    <div className="rounded-lg border shadow-sm p-4 lightBlueBg pt-6 border-gray-300 space-y-3">
      {children}
    </div>
  </div>
);

export const JobRequirements = ({ form, masterData = {}, onRoleChange }) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;
  const templateType = watch('templateType');

  const {
    jobTitles = [],
    businessUnits = [],
    careerLevels = [],
    minSalaryRanges = [],
    maxSalaryRanges = [],
    employmentTypes = [],
    locations = [],
  } = masterData;

  const jobTemplates = [
    {
      label: 'Custom Requirement',
      value: '1',
    },
    {
      label: 'Ahedge Template',
      value: '2',
    },
    {
      label: 'My Template',
      value: '3',
    },
  ];

  // Helper function to ensure consistent value types
  const formatValue = (value) => value?.toString() || undefined;

  return (
    <FormSection title="Job Requirements">
      <div className="space-y-3">
        <div>
          <Label htmlFor="templateType">Template Type</Label>
          <span className="text-red-500 ml-1">*</span>
          <div className="mt-1">
            <Controller
              name="templateType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onValueChange={field.onChange}
                  value={formatValue(field.value)}
                >
                  <SelectTrigger
                    id="templateType"
                    className={cn(
                      'w-full',
                      errors.templateType && 'border-red-500'
                    )}
                  >
                    <SelectValue placeholder="Select Template Type" />
                  </SelectTrigger>
                  <SelectContent>
                    {jobTemplates.map((template) => (
                      <SelectItem
                        key={template.value}
                        value={formatValue(template.value)}
                      >
                        {template.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>
        </div>
        {templateType === '2' && (
          <div>
            <Label htmlFor="jobTitle">Job Template</Label>
            <span className="text-red-500 ml-1">*</span>
            <div className="mt-1">
              <Controller
                name="jobTitle"
                control={control}
                rules={{ required: templateType === '2' }}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value);
                      // Find the selected job title's label
                      const selectedTitle = jobTitles.find(
                        (title) => title.value === value
                      );
                      if (selectedTitle) {
                        // Update the role input with the selected job title's label
                        setValue('role', selectedTitle.label);
                      }
                      onRoleChange?.(value);
                    }}
                    value={formatValue(field.value)}
                  >
                    <SelectTrigger
                      id="jobTitle"
                      className={cn(
                        'w-full',
                        errors.jobTitle && 'border-red-500'
                      )}
                    >
                      <SelectValue placeholder="Select Job Title" />
                    </SelectTrigger>
                    <SelectContent>
                      {jobTitles.map((title) => (
                        <SelectItem
                          key={title.value}
                          value={formatValue(title.value)}
                        >
                          {title.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          </div>
        )}
        <div>
          <Label htmlFor="role">Role</Label>
          <span className="text-red-500 ml-1">*</span>
          <div className="mt-1">
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  id="role"
                  className={cn('w-full', errors.role && 'border-red-500')}
                  placeholder="Enter the role"
                  value={field.value || ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  ref={field.ref}
                />
              )}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="businessUnit">Business Unit</Label>
            <span className="text-red-500 ml-1">*</span>
            <div className="mt-1">
              <Controller
                name="businessUnit"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) =>
                      field.onChange(formatValue(value))
                    }
                    value={formatValue(field.value)}
                  >
                    <SelectTrigger
                      id="businessUnit"
                      className={cn(
                        'w-full',
                        errors.businessUnit && 'border-red-500'
                      )}
                    >
                      <SelectValue placeholder="Select Business Unit" />
                    </SelectTrigger>
                    <SelectContent>
                      {businessUnits.map((unit) => (
                        <SelectItem
                          key={unit.value}
                          value={formatValue(unit.value)}
                        >
                          {unit.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          </div>

          <div>
            <Label htmlFor="careerLevel">Career Level</Label>
            <span className="text-red-500 ml-1">*</span>
            <div className="mt-1">
              <Controller
                name="careerLevel"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    onValueChange={(value) =>
                      field.onChange(formatValue(value))
                    }
                    value={formatValue(field.value)}
                  >
                    <SelectTrigger
                      id="careerLevel"
                      className={cn(
                        'w-full',
                        errors.careerLevel && 'border-red-500'
                      )}
                    >
                      <SelectValue placeholder="Select Career Level" />
                    </SelectTrigger>
                    <SelectContent>
                      {careerLevels.map((level) => (
                        <SelectItem
                          key={level.value}
                          value={formatValue(level.value)}
                        >
                          {level.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          </div>
        </div>

        <div>
          <Label htmlFor="description">Detailed Job Description</Label>
          <span className="text-red-500 ml-1">*</span>
          <div className="mt-1">
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Textarea
                  id="description"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value || ''}
                  ref={field.ref}
                  className={cn(
                    'w-full',
                    errors.description && 'border-red-500'
                  )}
                />
              )}
            />
          </div>
        </div>

        <div>
          <Label htmlFor="additionalInstructions">
            Additional Requirements or Preferences
          </Label>
          <div className="mt-1">
            <Controller
              name="additionalInstructions"
              control={control}
              render={({ field }) => (
                <Textarea
                  id="additionalInstructions"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value || ''}
                  ref={field.ref}
                />
              )}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="minSalary">Minimum Salary</Label>
            <Controller
              name="minSalary"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onValueChange={(value) => field.onChange(formatValue(value))}
                  value={formatValue(field.value)}
                >
                  <SelectTrigger
                    id="minSalary"
                    className={cn(
                      'w-full',
                      errors.minSalary && 'border-red-500'
                    )}
                  >
                    <SelectValue placeholder="Select Salary Range" />
                  </SelectTrigger>
                  <SelectContent>
                    {minSalaryRanges.map((range) => (
                      <SelectItem
                        key={range.value}
                        value={formatValue(range.value)}
                      >
                        {range.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>

          <div>
            <Label htmlFor="maxSalary">Maximum Salary</Label>
            <Controller
              name="maxSalary"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  onValueChange={(value) => field.onChange(formatValue(value))}
                  value={formatValue(field.value)}
                >
                  <SelectTrigger
                    id="maxSalary"
                    className={cn(
                      'w-full',
                      errors.maxSalary && 'border-red-500'
                    )}
                  >
                    <SelectValue placeholder="Select Salary Range" />
                  </SelectTrigger>
                  <SelectContent>
                    {maxSalaryRanges.map((range) => (
                      <SelectItem
                        key={range.value}
                        value={formatValue(range.value)}
                      >
                        {range.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>
          <LocationFields
            control={control}
            errors={errors}
            locations={locations}
          />
          <div>
            <Label>Employment Type</Label>
            <span className="text-red-500 ml-1">*</span>
            <Controller
              name="employmentType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup
                  onValueChange={field.onChange}
                  value={field.value}
                  className="flex space-x-4 mt-3"
                >
                  {employmentTypes.map((type) => (
                    <div key={type.id} className="flex items-center space-x-2">
                      <RadioGroupItem value={type.id} id={type.id} />
                      <Label htmlFor={type.id}>{type.value}</Label>
                    </div>
                  ))}
                </RadioGroup>
              )}
            />
          </div>
        </div>
      </div>
    </FormSection>
  );
};
