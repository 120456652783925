import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import { Alert } from 'src/components/common/Alert';
import { Label } from 'src/components/ui/label';
import { RequirementService } from 'src/services/api/RequirementService';
import SaveAsTemplateModal from 'src/Modals/SaveAsTemplateModal';
import {
  jobTitles,
  businessUnits,
  careerLevels,
  minEducations,
  minExperiences,
  fieldOptions,
  certificationOptions,
  skillOptions,
  // regulatoryOptions,
  employmentTypes,
} from './FormOptions';

const transformApiResponse = (apiData) => {
  const {
    id,
    title,
    detailed_job_description: detailedJobDescription,
    additional_requirements: additionalRequirements,
    minimum_salary: minimumSalary,
    maximum_salary: maximumSalary,
    employment_type: employmentType,
    closing_date: closingDate,
    career_level: careerLevel,
    business_unit: businessUnit,
    work_experience: workExperience,
    locations,
    qualifications,
    skills,
    certifications,
    selection_process: selectionProcess,
  } = apiData;

  return {
    id,
    jobTitle: title,
    description: detailedJobDescription,
    additionalInstructions: additionalRequirements || '',
    minSalary: minimumSalary?.toString(),
    maxSalary: maximumSalary?.toString(),
    employmentType,
    closingDate: closingDate ? new Date(closingDate) : null,
    careerLevel,
    businessUnit,
    minExperience: workExperience,
    locations: locations.map((loc) => ({
      location: loc.location_name,
      count: loc.headcount,
    })),
    fieldsOfStudy: qualifications.map((qual) => ({
      value: qual.field_of_study,
      isMandatory: qual.is_mandatory,
    })),
    professionalCertifications: certifications.map((cert) => ({
      value: cert.certification_name,
      isMandatory: cert.is_mandatory,
    })),
    skills: skills.map((skill) => ({
      value: skill.skill_name,
      isMandatory: skill.is_mandatory,
    })),
    selectionProcess: {
      steps: selectionProcess.map((step, index) => ({
        id: index + 1,
        text: step.process_name,
        checked: true,
        enabled: true,
        executedByCustomer: step.executed_by_customer,
      })),
    },
    minEducation: qualifications[0]?.qualification_type || '',
  };
};

const loadRequirementPreview = async (reqId) => {
  try {
    const response = await RequirementService.getRequirementPreviewData(reqId);

    if (response.success) {
      return {
        success: true,
        data: transformApiResponse(response.data),
        message: response.message,
      };
    }
    throw new Error(
      response.data.error || 'Failed to load requirement preview'
    );
  } catch (error) {
    return {
      success: false,
      data: null,
      message: error.message,
    };
  }
};

const PreviewField = ({ label, children }) => (
  <div>
    <Label className="block mb-1 font-semibold text-sm">{label}</Label>
    <div className="text-left text-sm">{children}</div>
  </div>
);

const Section = ({ title, children }) => (
  <div className="mt-3 relative">
    <div className="absolute -top-3 left-4 px-2 bg-background">
      <h4 className="font-bold pageTitle">{title}</h4>
    </div>
    <div className="rounded-lg border lightBlueBg shadow-sm px-4 py-7 border-gray-300 space-y-5 text-left">
      {children}
    </div>
  </div>
);

const findLabelByValue = (options, value) => {
  const option = options.find((opt) => opt.value === value);
  return option ? option.label : value;
};

const generateItemId = (prefix, value, isMandatory) =>
  `${prefix}-${value}-${isMandatory ? 'mandatory' : 'optional'}`;

const renderItems = (items, options, itemType) => {
  const mandatoryItems = items.filter((item) => item.isMandatory);
  const optionalItems = items.filter((item) => !item.isMandatory);

  return (
    <div
      className={`grid ${
        mandatoryItems.length > 0 && optionalItems.length > 0
          ? 'grid-cols-2'
          : 'grid-cols-1'
      } gap-4`}
    >
      {mandatoryItems.length > 0 && (
        <div>
          <h5 className="font-semibold mb-2">Mandatory:</h5>
          {mandatoryItems.map((item) => (
            <div
              key={generateItemId(itemType, item.value, true)}
              className="flex items-center mb-1"
            >
              <span className="text-red-500 mr-2 text-lg">☆</span>
              <span>{findLabelByValue(options, item.value)}</span>
            </div>
          ))}
        </div>
      )}
      {optionalItems.length > 0 && (
        <div>
          <h5 className="font-semibold mb-2">Optional:</h5>
          {optionalItems.map((item) => (
            <div
              key={generateItemId(itemType, item.value, false)}
              className="flex items-center mb-1"
            >
              <span className="text-gray-400 mr-2 text-lg">☆</span>
              <span>{findLabelByValue(options, item.value)}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
const getBreadcrumbItems = (id) => [
  { label: 'Home', href: '/' },
  { label: 'Requirements', href: '/requirements' },
  {
    label: 'Basic Info',
    href: `/requirements/basic${id ? `/${id}` : ''}`,
  },
  {
    label: 'Selection Process',
    href: `/requirements/selection${id ? `/${id}` : ''}`,
  },
  {
    label: 'Preview',
    href: `/requirements/preview${id ? `/${id}` : ''}`,
  },
];
const RequirementPreview = () => {
  const { reqId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const breadcrumbItems = getBreadcrumbItems(reqId);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertDescription, setAlertDescription] = useState('');
  const [requirementData, setRequirementData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false);
  const [templateTitle, setTemplateTitle] = useState('');
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      const response = await RequirementService.submitRequirement(reqId);

      if (response.success) {
        setAlertTitle('Submission Successful');
        setAlertDescription(
          'Your requirement has been submitted successfully!'
        );
        setIsAlertOpen(true);
      } else {
        throw new Error(response.message || 'Failed to submit requirement');
      }
    } catch (err) {
      setError(err.message);
    }
  }, [requirementData]);

  const handleConfirm = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleAlertClose = useCallback(() => {
    setIsAlertOpen(false);
    navigate('/requirements');
  }, [navigate]);

  const handleEdit = useCallback(() => {
    navigate(`/requirements/basic/${reqId}`);
  }, [navigate]);

  const handleBack = useCallback(() => {
    navigate(`/requirements/selection/${reqId}`);
  }, [navigate]);

  // Handle save as template
  const handleSaveAsTemplate = useCallback(() => {
    setTemplateTitle('');
    setIsTemplateDialogOpen(true);
  }, []);

  const handleCloseTemplateDialog = useCallback(() => {
    setIsTemplateDialogOpen(false);
  }, []);

  const handleTemplateSubmit = useCallback(async () => {
    if (!templateTitle.trim()) {
      return;
    }

    try {
      setIsSavingTemplate(true);

      // API call to save the requirement as a template
      const response = await RequirementService.saveAsTemplate(reqId, {
        custom_title: templateTitle,
      });

      if (response.success) {
        setIsTemplateDialogOpen(false);
        setAlertTitle('Template Saved');
        setAlertDescription(
          'The requirement has been saved as a template successfully!'
        );
        setIsAlertOpen(true);
      } else {
        throw new Error(response.message || 'Failed to save template');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSavingTemplate(false);
    }
  }, [reqId, templateTitle]);

  useEffect(() => {
    const loadPreview = async () => {
      try {
        const requirementId = reqId;

        if (!requirementId) {
          throw new Error('No requirement ID found');
        }
        const result = await loadRequirementPreview(requirementId);
        if (result.success) {
          setRequirementData(result.data);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadPreview();
  }, [location.state]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="mt-4">Loading preview data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center text-red-500 p-4 border border-red-300 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Error Loading Preview</h2>
          <p>{error}</p>
          <Button className="mt-4" onClick={() => window.location.reload()}>
            Retry
          </Button>
        </div>
      </div>
    );
  }

  if (!requirementData) {
    return <div>No requirement data found. Please start over.</div>;
  }

  return (
    <div className="text-left space-y-4">
      <Alert
        isOpen={isAlertOpen}
        onClose={handleAlertClose}
        title={alertTitle}
        description={alertDescription}
        actionText="OK"
      />
      <SaveAsTemplateModal
        isOpen={isTemplateDialogOpen}
        onClose={handleCloseTemplateDialog}
        title={templateTitle}
        setTitle={setTemplateTitle}
        onSubmit={handleTemplateSubmit}
        isSaving={isSavingTemplate}
        defaultJobTitle={findLabelByValue(jobTitles, requirementData?.jobTitle)}
      />
      <div className="flex items-center">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Preview Requirement
        </h1>
      </div>
      <div className="flex justify-between items-center">
        <Breadcrumb items={breadcrumbItems} className="flex-grow" />
        <Button
          variant="outline"
          onClick={handleSaveAsTemplate}
          className="border-primary bg-background text-primary hover:bg-primary/10 ml-4"
        >
          Save to Template
        </Button>
      </div>
      <div className="space-y-10 !mt-6">
        <Section title="Job Requirements">
          <PreviewField label="Job Title">
            {findLabelByValue(jobTitles, requirementData.jobTitle)}
          </PreviewField>

          <div className="grid grid-cols-3 gap-4 mt-4">
            <PreviewField label="Business Unit">
              {findLabelByValue(businessUnits, requirementData.businessUnit)}
            </PreviewField>
            <PreviewField label="Career Level">
              {findLabelByValue(careerLevels, requirementData.careerLevel)}
            </PreviewField>
            <PreviewField label="Minimum Experience">
              {findLabelByValue(minExperiences, requirementData.minExperience)}
            </PreviewField>
          </div>

          <div className="grid grid-cols-3 gap-4 mt-4">
            <PreviewField label="Minimum Salary">
              {requirementData.minSalary}
            </PreviewField>
            <PreviewField label="Maximum Salary">
              {requirementData.maxSalary}
            </PreviewField>
            <PreviewField label="Employment Type">
              {findLabelByValue(
                employmentTypes,
                requirementData.employmentType
              )}
            </PreviewField>
            <PreviewField label="Closing Date">
              {requirementData.closingDate?.toDateString()}
            </PreviewField>
            <PreviewField label="Locations">
              {requirementData.locations.map((loc) => (
                <div key={`location-${loc.location}-${loc.count}`}>
                  {loc.location}: {loc.count} positions
                </div>
              ))}
            </PreviewField>
          </div>

          <div className="mt-4">
            <PreviewField label="Description">
              {requirementData.description}
            </PreviewField>
          </div>

          <div className="mt-4">
            <PreviewField label="Additional Instructions">
              {requirementData.additionalInstructions}
            </PreviewField>
          </div>

          {/* <div className="mt-4">
            <PreviewField label="Locations">
              {requirementData.locations.map((loc) => (
                <div key={`location-${loc.location}-${loc.count}`}>
                  {loc.location}: {loc.count} positions
                </div>
              ))}
            </PreviewField>
          </div> */}
        </Section>

        <Section title="Eligibility">
          <PreviewField label="Minimum Education">
            {findLabelByValue(minEducations, requirementData.minEducation)}
          </PreviewField>
          <PreviewField label="Fields of Study">
            {renderItems(requirementData.fieldsOfStudy, fieldOptions, 'field')}
          </PreviewField>
        </Section>

        <Section title="Professional Certifications">
          <PreviewField label="">
            {renderItems(
              requirementData.professionalCertifications,
              certificationOptions,
              'cert'
            )}
          </PreviewField>
        </Section>

        <Section title="Skills and Knowledge">
          <PreviewField label="">
            {renderItems(requirementData.skills, skillOptions, 'skill')}
          </PreviewField>
        </Section>

        {/* <Section title="Regulatory Knowledge">
          <PreviewField label="">
            {renderItems(
              requirementData.regulatoryKnowledge,
              regulatoryOptions,
              'reg'
            )}
          </PreviewField>
        </Section> */}

        <Section title="Selection Process">
          <PreviewField label="Selected Steps">
            {requirementData.selectionProcess.steps
              .filter((step) => step.checked && step.enabled)
              .map((step) => (
                <div
                  key={step.id}
                  className="flex justify-between items-center mb-1"
                >
                  <span>{step.text}</span>
                  <span className="text-sm text-gray-500">
                    {step.executedByCustomer ? 'Customer' : 'Ahedge'}
                  </span>
                </div>
              ))}
          </PreviewField>
        </Section>
      </div>

      <div className="flex justify-between mt-6">
        <div className="space-x-2">
          <Button variant="outline" onClick={handleBack}>
            Back
          </Button>
          <Button onClick={handleEdit}>Edit</Button>
        </div>
        <Button onClick={handleConfirm}>Confirm and Submit</Button>
      </div>
    </div>
  );
};

export default RequirementPreview;
