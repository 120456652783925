import axios from 'axios';
import { API_ENDPOINTS } from './endpoints';

export const AuthService = {
  refreshToken: async (data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${API_ENDPOINTS.AUTH.REFRESH}`,
      data
    );
    return response;
  },
};
