import React, { useEffect } from 'react';
import { Button } from 'src/components/ui/button';
import { Label } from 'src/components/ui/label';
import { Input } from 'src/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from 'src/components/ui/dialog';

const SaveAsTemplateModal = ({
  isOpen,
  onClose,
  title,
  setTitle,
  onSubmit,
  isSaving,
  defaultJobTitle,
}) => {
  // Set the title to defaultJobTitle when the modal opens
  useEffect(() => {
    if (isOpen && defaultJobTitle && !title) {
      setTitle(defaultJobTitle);
    }
  }, [isOpen, defaultJobTitle, title, setTitle]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Save as Template</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="templateTitle" className="text-right">
              Title
            </Label>
            <Input
              id="templateTitle"
              className="col-span-3"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter template title"
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSubmit} disabled={!title.trim() || isSaving}>
            {isSaving ? 'Saving...' : 'Save Template'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SaveAsTemplateModal;
