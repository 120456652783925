import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import {
  Mail,
  Phone,
  CalendarDays,
  Loader2,
  MapPin,
  GraduationCap,
  Briefcase,
  Target,
  Award,
  Building,
  Download,
} from 'lucide-react';
import { format, parse } from 'date-fns';
import { CandidateService } from 'src/services/api/CandidateService';
import { useToast } from 'src/hooks/use-toast';
import { Breadcrumb } from 'src/components/common/Breadcrumb';
import { Alert } from 'src/components/common/Alert';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ResumePDF from 'src/pages/Customer/ResumePDF/ResumePDF';

const ProfileView = () => {
  const { profileId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { toast } = useToast();
  const reqId = searchParams.get('reqId');
  const [isLoading, setIsLoading] = useState(true);
  const [candidateData, setCandidateData] = useState(null);
  const [resumeData, setResumeData] = useState(null);
  const [error, setError] = useState(null);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showRejectAlert, setShowRejectAlert] = useState(false);
  const [showStatusUpdateAlert, setShowStatusUpdateAlert] = useState(false);
  const [pendingStatus, setPendingStatus] = useState(null);

  const breadcrumbItems = [
    { label: 'Home', href: '/' },
    { label: 'Requirements', href: '/requirements' },
    {
      label: 'Candidates',
      href: `/requirements/candidates?status=new&reqId=${searchParams.get(
        'reqId'
      )}`,
    },
    { label: 'Profile', href: `/requirements/candidate/${profileId}` },
  ];

  // Function to get action button text based on status
  const getActionButtonText = (status) => {
    switch (status?.toLowerCase()) {
      case 'submitted':
        return 'Shortlist';
      case 'shortlisted':
        return 'Send Interview Invitation';
      case 'pending_interview':
        return 'Extend Offer';
      case 'offer_extended':
        return 'Offer Accepted';
      case 'offer_accepted':
        return 'Joined';
      default:
        return '';
    }
  };
  // Data mapping function
  const mapCandidateDataToResumePDF = (candData) => {
    if (!candData) return {};

    // Extract personal information
    const personal = candData.personal_information || {};
    const address = personal.address || {};

    // Parse work experience dates
    const mapWorkExperiences = (experiences) =>
      (experiences || []).map((exp) => {
        // Parse dates (format: "MM/YYYY")
        const fromDate = exp.from_date ? exp.from_date.split('/') : [];
        const toDate = exp.to_date ? exp.to_date.split('/') : [];

        return {
          company: exp.company_name,
          role: exp.role,
          employment_period: {
            startMonth: fromDate[0] || '',
            startYear: fromDate[1] || '',
            endMonth: toDate[0] || '',
            endYear: toDate[1] || '',
          },
        };
      });

    // Map educational details
    const mapEducations = (educations) =>
      (educations || []).map((edu) => ({
        institution: edu.institution,
        qualification: edu.qualification_type,
        graduationYear: edu.graduation_year,
        calculatedScore:
          edu.score_type === 'GPA'
            ? `${edu.actual_score}/${edu.max_score}`
            : edu.calculated_score || edu.actual_score,
      }));

    // Create the transformed data object
    return {
      firstname: personal.first_name || '',
      middlename: personal.middle_name || '',
      lastname: personal.last_name || '',
      email: personal.email || '',
      contact: personal.phone || '',
      dob: personal.dob || '',

      // Address information
      addressLine1: address.address_line_1 || '',
      city: address.city || '',
      state: address.state || '',
      zipCode: address.zip_code || '',

      // Education, experience, skills and certifications
      educations: mapEducations(candData.educational_details),
      workExperiences: mapWorkExperiences(candData.work_experiences),
      skills: candData.skills || [],
      otherSkills: candData.other_details?.other_skills || '',
      certifications: candData.certifications || [],
      otherCertifications: candData.other_details?.other_certifications || '',
    };
  };
  const loadCandidateDetails = async () => {
    try {
      setIsLoading(true);
      const response = await CandidateService.getCandidateDetails(profileId);

      if (response.success) {
        const profileData = response?.data?.profile_data;
        setCandidateData(profileData);

        // Transform the data for the PDF
        const mappedResumeData = mapCandidateDataToResumePDF(profileData);
        setResumeData(mappedResumeData);
      } else {
        throw new Error(response.message || 'Failed to load candidate details');
      }
    } catch (err) {
      setError(err.message);
      toast({
        title: 'Error',
        description: err.message,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadCandidateDetails();
  }, [profileId, toast]);

  // Function to handle status update
  const handleStatusUpdate = async (newStatus) => {
    setIsActionLoading(true);
    try {
      const data = {
        status: newStatus,
        candidates: [profileId],
      };
      const response = await CandidateService.updateCandidateStatus(
        reqId,
        data
      );

      if (response.success) {
        toast({
          title: 'Success',
          description: `Candidate status updated to ${newStatus}`,
        });
        searchParams.set('status', newStatus.toLowerCase());
        navigate(`?${searchParams.toString()}`);
      } else {
        throw new Error(response.message || 'Failed to update status');
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message,
        variant: 'destructive',
      });
    } finally {
      setIsActionLoading(false);
    }
  };
  const handleRejectClick = () => {
    setShowRejectAlert(true);
  };
  const handleRejectConfirm = async () => {
    try {
      setIsActionLoading(true);
      const data = {
        status: 'REJECTED',
        candidates: [profileId],
      };
      const response = await CandidateService.updateCandidateStatus(
        reqId,
        data
      );
      if (response.success) {
        toast({
          title: 'Success',
          description: 'Candidate has been rejected',
        });
        searchParams.set('status', 'rejected');
        navigate(`?${searchParams.toString()}`);
      } else {
        throw new Error(response.message || 'Failed to reject candidate');
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: err.message,
        variant: 'destructive',
      });
    } finally {
      setIsActionLoading(false);
    }
  };

  // Function to get next status
  const getNextStatus = (currentStatus) => {
    switch (currentStatus?.toLowerCase()) {
      case 'submitted':
        return 'SHORTLISTED';
      case 'shortlisted':
        return 'PENDING_INTERVIEW';
      case 'pending_interview':
        return 'OFFER_EXTENDED';
      case 'offer_extended':
        return 'OFFER_ACCEPTED';
      case 'offer_accepted':
        return 'JOINED';
      default:
        return '';
    }
  };
  const getConfirmationMessage = (status) => {
    switch (status) {
      case 'SHORTLISTED':
        return 'Are you sure you want to shortlist this candidate?';
      case 'PENDING_INTERVIEW':
        return 'Are you sure you want to send interview invitation to this candidate?';
      case 'OFFER_EXTENDED':
        return 'Are you sure you want to extend an offer to this candidate?';
      case 'OFFER_ACCEPTED':
        return 'Are you sure the candidate has accepted the offer?';
      case 'JOINED':
        return 'Are you sure the candidate has joined the company?';
      default:
        return 'Are you sure you want to proceed with this action?';
    }
  };
  const currentStatus = searchParams.get('status');
  const actionButtonText = getActionButtonText(currentStatus);
  const showActionButton = [
    'submitted',
    'shortlisted',
    'pending_interview',
    'offer_extended',
    'offer_accepted',
  ].includes(currentStatus?.toLowerCase());
  const handleActionButtonClick = () => {
    const nextStatus = getNextStatus(currentStatus);
    setPendingStatus(nextStatus);
    setShowStatusUpdateAlert(true);
  };
  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
        <Loader2 className="h-8 w-8 animate-spin ahBlueText" />
        <p className="mt-4 text-sm text-gray-600">Loading candidate data...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (!candidateData) {
    return <div>No candidate data found.</div>;
  }

  const {
    personal_information: personalInfo,
    educational_details: educationalDetails,
    work_experiences: workExperiences,
    skills,
    certifications,
  } = candidateData;

  const formatScore = (score) => {
    if (!score) return '';

    // Convert to number and handle any potential string inputs
    const numScore = Number(score);

    // Check if it's a whole number
    if (Number.isInteger(numScore)) {
      return numScore.toString();
    }

    // If it has decimals, return as is (it will automatically remove trailing zeros)
    return numScore.toString();
  };

  return (
    <div className="text-left space-y-4">
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl pageTitle">
          Candidate Profile
        </h1>
        <div className="flex gap-3">
          {showActionButton && (
            <Button
              onClick={handleActionButtonClick}
              disabled={isActionLoading}
              className="bg-primary text-white hover:bg-primary/90"
            >
              {isActionLoading ? (
                <>
                  <Loader2 className="h-4 w-4 animate-spin mr-2" />
                  Processing...
                </>
              ) : (
                actionButtonText
              )}
            </Button>
          )}
          <Button
            variant="destructive"
            onClick={handleRejectClick}
            disabled={
              isActionLoading || currentStatus?.toLowerCase() === 'rejected'
            }
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
          >
            {isActionLoading ? (
              <>
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                Processing...
              </>
            ) : (
              'Reject'
            )}
          </Button>
        </div>
      </div>

      <Breadcrumb items={breadcrumbItems} />
      {resumeData && (
        <div className="text-end">
          <PDFDownloadLink
            document={<ResumePDF data={resumeData} />}
            fileName={`${personalInfo.first_name || ''}_${
              personalInfo.last_name || ''
            }_resume.pdf`}
            className="no-underline"
          >
            {({ loading }) => (
              <Button
                disabled={loading}
                className="bg-blue-600 text-white hover:bg-blue-700"
              >
                {loading ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Generating PDF...
                  </>
                ) : (
                  <>
                    Download PDF
                    <Download className="h-4 w-4 ml-2" />
                  </>
                )}
              </Button>
            )}
          </PDFDownloadLink>
        </div>
      )}

      {/* Personal Information */}
      <div className="card mb-6 !p-4 mt-6">
        <div className="flex flex-col md:flex-row gap-8 items-start">
          <div className="w-28 h-28 rounded-full ahBlueBg md:flex hidden items-center justify-center text-white text-3xl font-bold flex-shrink-0">
            {`${personalInfo.first_name?.[0]}${personalInfo.last_name?.[0]}`}
          </div>
          <div className="flex-grow">
            <h1 className="text-2xl font-bold mb-2 text-gray-900 text-left">
              {`${personalInfo.first_name} ${
                personalInfo.middle_name ? `${personalInfo.middle_name} ` : ''
              }${personalInfo.last_name}`}
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
              {personalInfo.dob && (
                <div className="flex items-center gap-2">
                  <CalendarDays className="h-4 w-4 ahBlueText" />
                  <span>
                    Born{' '}
                    {format(
                      parse(personalInfo.dob, 'dd/MM/yyyy', new Date()),
                      'dd MMM yyyy'
                    )}
                  </span>
                </div>
              )}
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4 ahBlueText" />
                <span>{personalInfo.email}</span>
              </div>
              <div className="flex items-center gap-2">
                <Phone className="h-4 w-4 ahBlueText" />
                <span>{personalInfo.phone}</span>
              </div>
              {personalInfo.address && (
                <>
                  <div className="flex items-center gap-2">
                    <MapPin className="h-4 w-4 ahBlueText" />
                    <span>{personalInfo.address.address_line_1}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Building className="h-4 w-4 ahBlueText" />
                    <span>
                      {`${personalInfo.address.city}, ${personalInfo.address.state} ${personalInfo.address.zip_code}`}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Work Experience */}
      {workExperiences?.length > 0 && (
        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <Briefcase className="h-5 w-5 ahBlueText" />
            <span>Work Experience</span>
          </h2>
          {workExperiences.map((exp) => (
            <div
              key={`${exp.company_name}-${exp.from_date}`}
              className="bgBlueHighlight p-4 rounded-xl mb-4 last:mb-0 text-left"
            >
              <h3 className="text-md font-semibold ahBlueText">
                {exp.role || ''}
              </h3>
              <div className="text-gray-600">{exp.company_name}</div>
              <div className="text-sm text-gray-500 mt-2">
                {`${exp.from_date} - ${exp.to_date || 'Present'}`}
                {exp.is_current && ' (Current)'}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Education */}
      {educationalDetails?.length > 0 && (
        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <GraduationCap className="h-5 w-5 ahBlueText" />
            <span>Education</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-left">
            {educationalDetails.map((edu) => {
              const isGPA = edu.qualification_type
                ?.toLowerCase()
                .includes('gpa');

              // Format scores based on whether it's GPA or regular marks
              const actualScore = isGPA
                ? formatScore(edu.actual_score)
                : formatScore(edu.actual_score);

              const maxScore = isGPA
                ? formatScore(edu.max_score)
                : formatScore(edu.max_score);

              return (
                <div
                  key={`${edu.institution}-${edu.graduation_year}`}
                  className="bgBlueHighlight p-4 rounded-xl"
                >
                  <div className="flex justify-between items-center mb-1">
                    <h3 className="text-md font-semibold ahBlueText">
                      {edu.qualification_type}
                    </h3>
                    <p className="text-sm text-gray-600 font-semibold">
                      {edu.graduation_year}
                    </p>
                  </div>
                  <p>{edu.institution}</p>
                  <div className="flex justify-between items-center text-sm text-gray-600 mt-1">
                    <div>
                      {edu.university && <span>{edu.university}</span>}
                      {edu.board_type && <span>Board: {edu.board_type}</span>}
                    </div>
                    <div className="px-3 py-1 ahBlueBg text-white rounded-full text-sm">
                      {`${actualScore} / ${maxScore} (${formatScore(
                        edu.calculated_score
                      )}%)`}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {/* Skills */}
      {skills?.length > 0 && (
        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <Target className="h-5 w-5 ahBlueText" />
            <span>Skills</span>
          </h2>
          <div className="flex flex-wrap gap-2">
            {skills.map((skill) => (
              <span
                key={`skill-${skill}`}
                className="px-4 py-2 bgBlueHighlight text-gray-600 rounded-full hover:ahBlueBg hover:text-white transition-colors"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Certifications */}
      {certifications?.length > 0 && (
        <div className="card !p-4">
          <h2 className="section-title flex items-center gap-1">
            <Award className="h-5 w-5 ahBlueText" />
            <span>Certifications</span>
          </h2>
          <div className="flex flex-wrap gap-2">
            {certifications.map((cert) => (
              <span
                key={`cert-${cert}`}
                className="px-4 py-2 bgBlueHighlight text-gray-600 rounded-full hover:ahBlueBg hover:text-white transition-colors"
              >
                {cert}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* Actions */}
      <div className="flex justify-end gap-4 mt-6">
        <Button variant="outline" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <Alert
        isOpen={showRejectAlert}
        onClose={() => setShowRejectAlert(false)}
        title="Confirm Reject"
        description="Are you sure you want to reject this candidate? This action cannot be undone."
        cancelText="Cancel"
        continueText={isActionLoading ? 'Processing...' : 'Reject'}
        onCancel={() => setShowRejectAlert(false)}
        onContinue={handleRejectConfirm}
      />
      <Alert
        isOpen={showStatusUpdateAlert}
        onClose={() => setShowStatusUpdateAlert(false)}
        title="Confirm Status Update"
        description={getConfirmationMessage(pendingStatus)}
        cancelText="Cancel"
        continueText={isActionLoading ? 'Processing...' : 'Continue'}
        onCancel={() => setShowStatusUpdateAlert(false)}
        onContinue={() => {
          handleStatusUpdate(pendingStatus);
          setShowStatusUpdateAlert(false);
        }}
      />
    </div>
  );
};

export default ProfileView;
